import React from 'react';
import { TransactionModal } from '@components';

const ListHeader = () => {
  return (
    <div className="list_header">
      <div className="item">
        <p className="list_header_item">Token</p>
      </div>
      <div className="item">
        <p className="list_header_item">Type</p>
      </div>
      <div className="item">
        <p className="list_header_item">Amount</p>
      </div>
      <div className="item">
        <p className="list_header_item">Result</p>
      </div>
    </div>
  );
};

const ListItem = ({ item, openModal }) => {
  const date = new Date();
  const end = new Date(item?.endDate);
  const [status, setStatus] = React.useState(
    date.getTime() < end.getTime() ? 'open' : item?.status,
  );

  return (
    <div className="list_item" onClick={() => openModal(item)}>
      <div className="item">
        <p className="list_item_item">{item?.coinId}</p>
      </div>
      <div className="item">
        <p className="list_item_item">{item?.type}</p>
      </div>
      <div className="item">
        <p className="list_item_item">${Math.abs(item?.limitPrice)}</p>
      </div>
      <div className="item">
        <p className="list_item_item">
          {item?.status === 'closed' ? (
            item?.profit !== 0 ? (
              <p className="green">Profit (+{item.profit}$)</p>
            ) : (
              <p className="red">Loss (-{Math.abs(item.loss)}$)</p>
            )
          ) : (
            <p>Pending</p>
          )}
        </p>
      </div>
    </div>
  );
};

const MobileTransactionList = ({ history, mt }) => {
  const [transaction, setTransaction] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const openModal = (t) => {
    setTransaction(t);
    setModal(true);
  };

  const closeModal = () => {
	window.location.reload();
    setTransaction(null);
    setModal(false);
  };

  return (
    <div className={`mobile_transaction_list ${mt}`}>
      {modal && (
        <TransactionModal transaction={transaction} closeModal={closeModal} />
      )}
      <h1 className="heading">Your Transactions</h1>
      {!history || history.length === 0 ? (
        <div className="empty_list">
          <p>No transaction history</p>
        </div>
      ) : (
        <div className="list_container">
          <ListHeader />
          <div className="list_body">
            {history?.map((item, index) => (
              <ListItem item={item} key={index} openModal={openModal} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTransactionList;
