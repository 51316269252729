import React from 'react';

const useTrading = (userId) => {
  const server_url = 'https://server.nextwebtrade.com';
  const route = '/trading';
  const checkRoute = '/trading/check';

  const [trades, setTrades] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getTrades = async () => {
    setLoading(true);
    const param = userId ? `?userId=${userId}` : '';
    const response = await fetch(`${server_url}${route}${param}`);
    const data = await response.json();
    setTrades(data);
    setLoading(false);
  };

  const checkTrade = async (trade) => {
    const response = await fetch(`${server_url}${checkRoute}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tradeId: trade._id }),
    });
  };

  //   check trades every 5 seconds
  React.useEffect(() => {
    if (userId) getTrades();
  }, [userId]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (trades.length > 0) {
        trades.forEach((trade) => {
          checkTrade(trade);
        });
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [trades]);

  return { trades, getTrades };
};

export default useTrading;
