import React, { useState, useEffect } from 'react';

const Timer = ({ changeStatus, endDate }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const now = new Date();
    const targetDate = new Date(endDate);
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const timeLeft = calculateTimeRemaining();
      setTimeRemaining(timeLeft);
      if (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
        changeStatus('closed');
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [endDate]);

  const makeString = () => {
    let string = '';
    string += timeRemaining.days > 0 ? `${timeRemaining.days}d ` : '';
    string += timeRemaining.hours > 0 ? `${timeRemaining.hours}h ` : '';
    string += timeRemaining.minutes > 0 ? `${timeRemaining.minutes}m ` : '';
    string += `${timeRemaining.seconds}s`;
    return string;
  };

  return <>{makeString()}</>;
};

export default Timer;
