// creating auth context
import React, { createContext, useEffect, useState } from 'react';
import { LoaderContext } from '../App';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // get token from local storage
    const token = localStorage.getItem('token');
    // if token exists
    if (token) {
      let user;
      if (localStorage.getItem('user'))
        user = JSON.parse(localStorage.getItem('user'));
      setUser(user);
      setAuth(token);
    }
  }, []);

  const server_url = 'https://server.nextwebtrade.com';

  const checkUsername = async (username) => {
    const res = await fetch(`${server_url}/auth/checkname`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username }),
    });
    const data = await res.json();
    return data;
  };

  const checkEmail = async (email) => {
    const res = await fetch(`${server_url}/auth/checkemail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const data = await res.json();
    return data;
  };

  const signin = async (email, password) => {
    setLoading(true);
    const res = await fetch(`${server_url}/auth/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await res.json();
    if (data.error) {
      setLoading(false);
      return data;
    } else {
      if (data.token && data.result) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.result));
        setUser(data.user);
        setAuth(data.token);
      }
    }
    setLoading(false);
    return data;
  };

  const signup = async (name, email, password) => {
    setLoading(true);
    const res = await fetch(`${server_url}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, password, verified: true }),
    });
    const data = await res.json();
    if (data.error) {
      setLoading(false);
      return data;
    }
    if (data) {
      if (data.token && data.result) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.result));
        setUser(data.user);
        setAuth(data.token);
      }
      //   const verification = await sendVerification(data?.result?._id);
      //   return verification;
    }
    setLoading(false);
    return data;
  };

  const signout = () => {
    setLoading(true);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    setAuth(null);
    window.location.href = '/';
  };

  const sendVerification = async (id) => {
    if (!id || id === '') return;
    const res = await fetch(`${server_url}/auth/sendmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    const data = await res.json();
    return data;
  };

  const verify = async (id, code) => {
    const res = await fetch(`${server_url}/auth/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, code }),
    });
    const data = await res.json();
    const user = JSON.parse(localStorage.getItem('user'));
    user.verified = true;
    localStorage.setItem('user', JSON.stringify(user));
    return data;
  };

  const sendResetMail = async (email) => {
    const res = await fetch(`${server_url}/auth/passmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    const data = await res.json();
    return data;
  };

  const reset = async (id, token, password) => {
    const res = await fetch(`${server_url}/auth/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, token, password }),
    });
    const data = await res.json();
    return data;
  };

  const values = {
    auth,
    user,
    loading,
    signin,
    signup,
    signout,
    sendVerification,
    verify,
    checkUsername,
    checkEmail,
    sendResetMail,
    reset,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthContext;
