import React from 'react';

const useWithdraw = ({ closeModal }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) window.location.href = '/login';

  const server_url = 'https://server.nextwebtrade.com';
  const route = '/withdraws';

  const [amount, setAmount] = React.useState(0);
  const [address, setAddress] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleAmountChange = (e, balance) => {
	console.log(typeof Number(e.target.value));
    if (Number(e.target.value) > balance) {
      setError('Insufficient funds');
    } else {
      setError(null);
      setAmount(e.target.value);
    }
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const withdraw = async () => {
    setLoading(true);
    if (amount <= 0 || !address) return setLoading(false);
    const response = await fetch(`${server_url}${route}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        walletAddress: address,
        userId: user?._id,
      }),
    });
    if (response.ok) {
      closeModal();
    }
    setLoading(false);
  };

  return {
    loading,
    withdraw,
    amount,
    address,
    handleAmountChange,
    handleAddressChange,
    error,
  };
};

export default useWithdraw;
