import React from 'react';

const useWatchList = () => {
  const api_url = 'https://openapiv1.coinstats.app/coins?limit=200';
  const api_options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'X-API-KEY': 's3h6rqQumF9/OIJ9rdiAlaLqCwnyIMSFFtHF18SKQ8I=',
    },
  };

  const server_url = 'https://server.nextwebtrade.com';

  const [watchList, setWatchList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [coins, setCoins] = React.useState([]);

  const user = JSON.parse(localStorage.getItem('user'));
  const id = user && user?._id;

  const getWatchList = async () => {
    if (!id) return;
    setLoading(true);
    const response = await fetch(`${server_url}/watchlist/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token') || '',
      },
    });
    const data = await response.json();
    setWatchList(data);
    setLoading(false);
  };

  const getCoins = async () => {
    setLoading(true);
    const response = await fetch(api_url, api_options);
    const data = await response.json();
    setCoins(data.result);
    setLoading(false);
  };

  React.useEffect(() => {
    getWatchList();
    getCoins();
  }, [id]);

  const addToWatchList = async (coin) => {
    setLoading(true);
    const response = await fetch(`${server_url}/watchlist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: id, coinId: coin }),
    });
    const data = await response.json();
    setWatchList([...watchList, data]);
    setLoading(false);
  };

  const removeFromWatchList = async (coin) => {
    setLoading(true);
    const response = await fetch(`${server_url}/watchlist/${coin?._id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if (data.error) console.log(data.error);
    if (!data.error) {
      console.log('removed');
      setWatchList(watchList.filter((item) => item.id !== coin.id));
    }
    setLoading(false);
  };

  return {
    coins,
    watchList,
    loading,
    addToWatchList,
    removeFromWatchList,
    getCoins,
  };
};

export default useWatchList;
