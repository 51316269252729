import React from 'react';

const useWallet = () => {
  const server_url = 'https://server.nextwebtrade.com';
  const route = 'wallets';

  const [wallets, setWallets] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) window.location.href = '/login';

  const getWallets = async () => {
    setLoading(true);
    const response1 = await fetch(`${server_url}/deposits`);
    const data1 = await response1.json();
    const response2 = await fetch(`${server_url}/withdraws`);
    const data2 = await response2.json();
    setWallets({
      deposits: data1.filter((item) => item.userId === user?._id),
      withdraws: data2.filter((item) => item.userId === user?._id),
    });
    setLoading(false);
  };

  const createWallet = async (wallet) => {
    setLoading(true);
    const response = await fetch(`${server_url}/${route}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(wallet),
    });
    const data = await response.json();
    setWallets([...wallets, data]);
    setLoading(false);
  };

  const updateWallet = async (wallet) => {
    setLoading(true);
    const response = await fetch(`${server_url}/${route}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(wallet),
    });
    const data = await response.json();
    const index = wallets.findIndex((w) => w.id === wallet.id);
    setWallets([...wallets.slice(0, index), data, ...wallets.slice(index + 1)]);
    setLoading(false);
  };

  const deleteWallet = async (id) => {
    setLoading(true);
    const response = await fetch(`${server_url}/${route}/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    setWallets(wallets.filter((w) => w.id !== id));
    setLoading(false);
  };

  React.useEffect(() => {
    getWallets();
  }, []);

  return {
    loading,
    wallets,
    setWallets,
    getWallets,
    createWallet,
    updateWallet,
    deleteWallet,
  };
};

export default useWallet;
