import React, { useState } from 'react';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';

const FAQAccordion = ({ faqs }) => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <div className="w-full mx-auto flex flex-col gap-5">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className="flex flex-col p-7 border-[1px] border-[#3b3b3b] rounded-2xl bg-[#000000] backdrop-blur-[2rem] gap-0"
        >
          <button
            className="w-full flex justify-between items-center p-4 focus:outline-none"
            onClick={() => toggleAccordion(index)}
          >
            <span className="text-xl Question-Text font-regular text-left">{faq.question}</span>
            {openIndex === index ? (
              <ArrowUp2 size="20" color='black' />
            ) : (
              <ArrowDown2 size="20" color='black'/>
            )}
          </button>
          <div
            className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
              openIndex === index ? 'max-h-96' : 'max-h-0'
            }`}
          >
            <p className="p-4 text-white font-extralight">{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQAccordion;
