import React from 'react';
import useUser from '@hooks/useUser';

const useTrade = (coins, coin, setCoin) => {
  const server_url = 'https://server.nextwebtrade.com';
  const route = '/trade';
  const tradingRoute = '/trading';

  const u = JSON.parse(localStorage.getItem('user'));
  const { user } = useUser(u?._id);
  const [loading, setLoading] = React.useState(false);

  const [activeButton, setActiveButton] = React.useState('buy');
  const [coinSelector, setCoinSelector] = React.useState(false);
  const [timer, setTimer] = React.useState({
    time: '30s',
    rate: '10%',
    min: 100,
  });
  const [max, setMax] = React.useState(0);
  const [selector, setSelector] = React.useState(
    `${coin?.symbol} - $${coin?.price?.toFixed(2)}`,
  );
  const times = [
    { time: '30s', rate: '10%', min: 100 },
    { time: '60s', rate: '15%', min: 1000 },
    { time: '90s', rate: '20%', min: 5000 },
    { time: '2min', rate: '25%', min: 10000 },
    { time: '3min', rate: '30%', min: 30000 },
    { time: '4min', rate: '40%', min: 100000 },
    { time: '5min', rate: '80%', min: 200000 },
    { time: '10min', rate: '100%', min: 500000 },
  ];
  React.useEffect(() => {
    setCoin(coins[0]);
  }, [coins]);
  React.useEffect(() => {
    setSelector(`${coin?.symbol} - $${coin?.price?.toFixed(2)}`);
  }, [coin]);

  const [result, setResult] = React.useState(null);

  const getTrade = async (id) => {
    setLoading(true);
    const response = await fetch(`${server_url}${route}/${id}`);
    const data = await response.json();
    setResult(data);
    setLoading(false);
  };

  const trade = async (tradeInfo) => {
    setLoading(true);
    const durations = {
      '30s': 30,
      '60s': 60,
      '90s': 90,
      '2min': 120,
      '3min': 180,
      '4min': 240,
      '5min': 300,
      '10min': 600,
    };

    const purcentages = {
      '30s': 10,
      '60s': 15,
      '90s': 20,
      '2min': 25,
      '3min': 30,
      '4min': 40,
      '5min': 80,
      '10min': 100,
    };

    // set the end date to the current date + the duration

    const startDate = new Date();

    function addSecondsToDate(seconds) {
      const currentDate = startDate;
      const futureDate = new Date(currentDate.getTime() + seconds * 1000);

      // Format the date to 'YYYY-MM-DD HH:mm:ss'

      return futureDate;
    }

    const endDate = addSecondsToDate(durations[tradeInfo.duration]);
    if (user.status !== 'profit' && user.status !== 'loss') {
      // set random status
      const random = Math.random();
      if (random > 0.5) {
        user.status = 'profit';
      } else {
        user.status = 'loss';
      }
    }
    const body = {
      userId: u?._id,
      startDate,
      endDate,
      duration: durations[tradeInfo.duration],
      ended: true,
      status: 'pending',
      coinId: tradeInfo.coin,
      limitPrice:
        user.status == 'loss' ? -1 * tradeInfo.amount : tradeInfo.amount,
      type: tradeInfo.type,
      profit:
        user.status == 'profit'
          ? tradeInfo.amount * (purcentages[tradeInfo.duration] / 100)
          : 0,
      loss:
        -1 *
        (user.status == 'loss'
          ? tradeInfo.amount * (purcentages[tradeInfo.duration] / 100)
          : 0),
    };

    const tradingBody = {
      userId: u?._id,
      type: tradeInfo.type,
      startDate,
      endDate,
      ended: false,
      amount: tradeInfo.amount,
      purcentage: purcentages[tradeInfo.duration],
      result: 'pending',
      status: user.status,
    };

    // POST /trades (create a new trade)
    await fetch(`${server_url}${tradingRoute}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(tradingBody),
    });

    const response = await fetch(`${server_url}${route}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();
    try {
      // let userInfo = user;
      // userInfo.totalBalance = user.totalBalance - tradeInfo.amount;
      const newUSer = {
        id: user?._id,
        totalBalance: user.totalBalance - tradeInfo.amount,
      };
      const response = await fetch(server_url + '/users', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUSer),
      });
      const json = await response.json();

      /*
      TODO: Refresh elements with new data..
      */

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }

    window.location.reload();
  };

  React.useEffect(() => {
    getTrade(u?._id);
  }, []);

  return {
    loading,
    trade,
    activeButton,
    setActiveButton,
    coinSelector,
    setCoinSelector,
    timer,
    setTimer,
    max,
    setMax,
    selector,
    times,
    result,
  };
};

export const useTradeHistory = () => {
  const server_url = 'https://server.nextwebtrade.com';
  const route = '/trade';

  const u = JSON.parse(localStorage.getItem('user'));
  const { user, updateUser } = useUser(u?._id);

  const [result, setResult] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const getTrade = async (id) => {
    setLoading(true);
    const response = await fetch(`${server_url}${route}/${id}`);
    const data = await response.json();
    setResult(data?.reverse());
    // update finished trades
    data?.forEach(async (trade) => {
      const now = new Date();
      const endDate = new Date(trade.endDate);
      if (now.getTime() > endDate.getTime()) {
        const newTrade = trade;
        newTrade.ended = true;
        newTrade.status = 'closed';
        const response = await fetch(server_url + '/trade', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newTrade),
        });
        const json = await response.json();
      }
    });
    setLoading(false);
  };

  const deleteTrade = async (id) => {
    setLoading(true);
    const response = await fetch(`${server_url}${route}/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
    setResult(result.filter((r) => r.id !== id));
    setLoading(false);
  };

  const updateManyTrades = async (coinId) => {
    setLoading(true);
    const response = await fetch(`${server_url}${route}/updateMany`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ coinId }),
    });
    const data = await response.json();
    setLoading(false);
  };

  React.useEffect(() => {
    getTrade(u?._id);
  }, []);

  return { result, deleteTrade, loading, updateManyTrades };
};

export default useTrade;
