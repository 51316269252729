import React from 'react';
import { Nav } from '@components';
import { Eye, EyeOff } from 'react-feather';
import { useAuth } from '@hooks/useAuth';
import { LoaderContext } from '../../App';
import { LoaderOverlay } from '@components';

const Signin = () => {
  document.title = 'Webtrade - Signin';
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);

  const { signin, user, loading } = useAuth();
  const { setLoading } = React.useContext(LoaderContext);
  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);

  if (user) {
    return window.location.replace('/dashboard/?view=dashboard');
  }

  const sign = async (e) => {
    e.preventDefault();
    if (email === '' || password === '') {
      setError('Please fill in all fields');
      return;
    }
    const res = await signin(email, password);
    if (res?.message || res?.error)
      setError(res.error ? res.error : res.message);
    else {
      window.location.replace('/dashboard/?view=dashboard');
    }
  };

  return !user ? (
    <section className="auth_page">
      {loading && <LoaderOverlay />}
      <Nav hide="signin" />
      <main className="auth_container">
        <div className="auth_title">
          <h1 className="auth_title">Sign in</h1>
        </div>
        <form className="auth_form">
          <div className="auth_form_group">
            <label htmlFor="email">Email</label>
            <input
              onFocus={() => setError(null)}
              type="email"
              id="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Enter your email"
            />
          </div>
          <div className="auth_form_group">
            <label htmlFor="password">Password</label>
            <div className="password_input">
              <input
                onFocus={() => setError(null)}
                type={passwordVisible ? 'text' : 'password'}
                autoCorrect="off"
                autoCapitalize="none"
                autoComplete="off"
                autoSave="off"
                id="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="Enter your password"
              />
              <div className="eye_container">
                {passwordVisible ? (
                  <EyeOff
                    style={{ cursor: 'pointer' }}
                    color="rgba(119, 237, 145, .2)"
                    className="password_toggle"
                    onClick={() => setPasswordVisible(false)}
                  />
                ) : (
                  <Eye
                    style={{ cursor: 'pointer' }}
                    color="rgba(119, 237, 145, 1)"
                    className="password_toggle"
                    onClick={() => setPasswordVisible(true)}
                  />
                )}
              </div>
            </div>
          </div>
          {error && <p className="error">{error}</p>}
          <button onClick={sign}>Sign in</button>
          <p
            className="green p_elem_link"
            onClick={() => window.location.replace('/auth/reset')}
          >
            Forgot your password?
          </p>
        </form>
      </main>
    </section>
  ) : (
    <></>
  );
};

export default Signin;
