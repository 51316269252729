import React, { useEffect } from 'react';

const useDeposit = ({ closeModal }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (!user) window.location.href = '/login';

  const server_url = 'https://server.nextwebtrade.com';
  const route = '/deposits';

  const [copied, setCopied] = React.useState([false, false, false, false]);
  const [amount, setAmount] = React.useState(0);
  const [hash, setHash] = React.useState('');
  const [proof, setProof] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [address, setAdress] = React.useState([]);

  React.useEffect(() => {
    const adress_route = 'adress';
    // const temp = [
    // 	{
    // 		hash: "TEY593GAKm3F64eMtu1GPiKxz6qCR5p9GA",
    // 		network: "USDT TRC20",
    // 	},
    // 	{
    // 		hash: "0xe99aAD39CEdcC0EC2175BCB302ceb47123557a17",
    // 		network: "USDT ERC20",
    // 	},
    // 	{
    // 		hash: "bc1qlzz8wnnhh990s580qldacejwkddt0damjhgwh0",
    // 		network: "BTC",
    // 	},
    // 	{
    // 		hash: "0xe99aAD39CEdcC0EC2175BCB302ceb47123557a17",
    // 		network: "ETH",
    // 	},
    // ];
    // setAdress(temp);
    const getAdress = async () => {
      const response = await fetch(`${server_url}/${adress_route}`);
      const data = await response.json();
      setAdress(data);
    };
    getAdress();
  }, []);

  const copy = (index) => {
    if (copied[index]) return;
    window.navigator.clipboard.writeText(address?.[index]?.hash);
    setCopied(copied?.map((val, i) => (i === index ? true : val)));
    setTimeout(() => {
      setCopied(copied?.map((val, i) => (i === index ? false : val)));
    }, 3000);
  };

  const handleFileChange = (e) => {
    // Update the state with the selected file
    setProof(e.target.files[0]);
  };

  const handleAmountChange = (e) => {
    // Update the state with the entered amount
    setAmount(e.target.value);
  };

  const handleHashChange = (e) => {
    // Update the state with the entered hash
    setHash(e.target.value);
  };

  const deposit = async () => {
    setLoading(true);
    if (amount <= 0 || !proof)
      return setError(
        amount <= 0 ? 'Please fill all fields' : 'Please upload a proof',
      );
    // Create FormData object to send file and other form data
    const formData = new FormData();
    formData.append('amount', amount);
    formData.append('proofHash', hash);
    formData.append('image', proof);
    formData.append('userId', user?._id);

    try {
      // Make a POST request to the server
      const response = await fetch(`${server_url}${route}`, {
        method: 'POST',
        body: formData,
      });

      // Handle the response
      if (response.ok) {
        setAmount(0);
        setHash('');
        setProof(null);
        closeModal();
        // Add any further actions you want to perform upon successful deposit
      } else {
        console.error('Deposit failed');
        // Handle error cases
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error during deposit:', error);
    }
  };

  return {
    address,
    loading,
    copied,
    copy,
    amount,
    proof,
    handleAmountChange,
    hash,
    handleHashChange,
    handleFileChange,
    deposit,
  };
};

export default useDeposit;
